<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="companySelected"
                  v-on:change="setCompany($event)"
                  :items="companies"
                  item-text="name"
                  return-object
                  label="Select Company"
                  prepend-inner-icon="business"
                  outlined
                  dense
                >
                  <template slot="item" slot-scope="companies">
                    <!-- HTML that describe how select should render selected items -->
                    <div class="v-list-item__content">
                      <div
                        class="v-list-item__title"
                        :class="
                          companyIsSubmitted(companies.item.id)
                            ? 'green--text '
                            : ''
                        "
                      >
                        {{ companies.item.name }}
                        {{ companyIsSubmitted(companies.item.id) ? "✓" : "" }}
                        <!-- :class="companyIsSubmitted(companies.item.id) ? 'green--text' : 'red--text'" -->
                      </div>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <date-picker
                @clicked="setDateRange"
                v-model="datesPicked"
                :colWidth="4"
              ></date-picker>
              <v-col cols="4">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  block
                  color="primary"
                  @click="createCompanyInvoice()"
                  >View Billing Data</v-btn
                >
              </v-col>

              <v-col cols="4">
                <v-btn
                  block
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  outlined
                  @click="createGlobalInvoice()"
                  >View Billing Data for every Company</v-btn
                >
              </v-col>
              <v-col cols="4">
                <EmailAccManButton :invoiceNumber="invoiceNumber" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card v-if="missingMargin.length > 0" class="mb-4 pa-4 red lighten-2">
        <v-container>
          <h2><b>The following margin errors were encountered</b></h2>
          <p v-for="error in missingMargin" :key="error">{{ error }}</p>
        </v-container>
      </v-card>
      <div
        class="v-card v-card--outlined v-sheet theme--light mb-4"
        v-if="processedData.length > 0"
      >
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row class="control columns">
              <div class="column is-one-fifth">
                <label for="setInvoiceDate">Set Invoice Date:</label>
                <input
                  type="date"
                  class="input"
                  name="setInvoiceDate"
                  id="setInvoiceDate"
                  v-model="query.setInvoiceDate"
                  v-on:change="enableFileExport()"
                />
                <div class="errorMessage">
                  <p>Set Invoice Date to Export File</p>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-card v-if="combinedChargeCodes.length > 0" class="mb-4">
        <v-container>
          <h2 class="title">{{ companyName }} Summary</h2>
          <div class="summary">
            <div class="box-div">
              <h3 class="title">This weeks Summary</h3>
              <div v-for="charge in combinedChargeCodes" v-bind:key="charge.id">
                <strong>{{ charge.code }}</strong> - {{ charge.name }} -
                {{ renderCurrency(charge.amount) }}
              </div>
              <strong>Total: {{ renderCurrency(totalAmountCombined) }}</strong>
            </div>
            <div class="box-div">
              <h3 class="title">Records Returned this week.</h3>
              <div>
                <strong>{{ snapBillingData.length }}</strong> Records from Snap
                Data.
              </div>
              <div>
                <strong>{{ sortedBillingData.length }}</strong> Records from
                Sorted.
              </div>
              <div>
                <strong>{{ recurringCharges.length }}</strong> Recurring
                Charges.
              </div>
              <div>
                <strong>{{ adhocCharges.length }}</strong> Ad-hoc Charges.
              </div>
            </div>
            <div class="box-div">
              <h3 class="title">Average for the past 5 weeks</h3>
              <div v-for="charge in averageCharges" v-bind:key="charge.id">
                <strong>{{ charge.account_code }}</strong> -
                {{ charge.description }} -
                {{ renderCurrency(charge.average) }} :
                <span :class="checkAverageStatus(percentageDiff(charge))">
                  {{ percentageDiff(charge) }}%</span
                >
              </div>
              <strong
                >Total: {{ renderCurrency(totalAverageCharge) }} :
                <span
                  :class="
                    checkAverageStatus(
                      getPercentageChange(
                        totalAverageCharge,
                        totalAmountCombined
                      )
                    )
                  "
                >
                  {{
                    getPercentageChange(
                      totalAverageCharge,
                      totalAmountCombined
                    )
                  }}%
                </span></strong
              >
            </div>
          </div>
        </v-container>
      </v-card>
      <div class="snapBillingTable" v-if="processedData.length > 0">
        <v-btn
          @click="insertBillingData()"
          type="button"
          :disabled="
            invoiceDateSet ||
            loading ||
            (marginErrors && missingMargin.length > 0)
          "
          class="mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
          >Submit Billing Data</v-btn
        >
        <!-- add charge button and form -->
        <v-dialog v-model="dialog" max-width="55vw">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mb-2 ml-2"
              v-on="on"
              :disabled="disableAddCharge"
              >Add Charge</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Charge to Invoice</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <!-- card with options to include adhoc charges on bulk upload -->
                <v-card class="my-2 pa-4" v-if="alreadySubmitted" outlined>
                  <v-row>
                    <v-col cols="4">
                      Invoice data has already been submitted for this date
                      range. If you wish to add a charge to the already
                      submitted data, tick one of checkboxes:
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        v-model="includeChargeToCommitted"
                        @change="checkboxToggle(1)"
                        label="Include Adhoc Charge on submitted billing data"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        v-model="onlyAddChargeToCommitted"
                        @change="checkboxToggle(2)"
                        label="Only insert Adhoc Charge on submitted billing data"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
                <!-- form  -->
                <div
                  v-if="
                    !alreadySubmitted ||
                    includeChargeToCommitted ||
                    onlyAddChargeToCommitted
                  "
                >
                  <label for="client">Client:</label>
                  <input
                    disabled
                    class="input column is-3"
                    placeholder="Selected Client"
                    type="text"
                    name="client"
                    id="client"
                    v-model="adhocCharge.client"
                  />
                  <div class="errorMessage" v-if="errors.client">
                    <p>{{ errors.client }}</p>
                  </div>
                  <label for="chargeDate">Date to Apply:</label>
                  <input
                    type="date"
                    class="input column"
                    name="chargeDate"
                    id="chargeDate"
                    v-model="adhocCharge.chargeDate"
                    :min="datesPicked[0]"
                    :max="datesPicked[1]"
                  />
                  <div class="errorMessage" v-if="errors.chargeDate">
                    <p>{{ errors.chargeDate }}</p>
                  </div>
                  <label for="chargeType">Charge Type:</label>
                  <input
                    required
                    placeholder="Type of Charge being applied"
                    type="text"
                    class="input"
                    name="chargeType"
                    id="chargeType"
                    v-model="adhocCharge.chargeType"
                  />
                  <div class="errorMessage" v-if="errors.chargeType">
                    <p>{{ errors.chargeType }}</p>
                  </div>
                  <label for="reference">Reference:</label>
                  <input
                    required
                    placeholder="Reference Number"
                    type="text"
                    class="input"
                    name="reference"
                    id="reference"
                    v-model="adhocCharge.reference"
                  />
                  <div class="errorMessage" v-if="errors.reference">
                    <p>{{ errors.reference }}</p>
                  </div>
                  <label for="clientReference">Client Reference:</label>
                  <input
                    required
                    placeholder="Reference which client will identify charge."
                    type="text"
                    class="input"
                    name="clientReference"
                    id="clientReference"
                    v-model="adhocCharge.clientReference"
                  />
                  <div class="errorMessage" v-if="errors.clientReference">
                    <p>{{ errors.clientReference }}</p>
                  </div>
                  <label for="UOM">Unit of Measurement:</label>
                  <input
                    placeholder="How is the charge measured."
                    type="text"
                    class="input"
                    name="UOM"
                    id="UOM"
                    v-model="adhocCharge.UOM"
                  />
                  <label for="billableUnits">Billable Units:</label>
                  <input
                    placeholder="Amount of units for Charge"
                    type="text"
                    class="input"
                    name="billableUnits"
                    id="billableUnits"
                    v-model="adhocCharge.billableUnits"
                  />
                  <label for="tariff">Tariff:</label>
                  <input
                    placeholder="The customers tarriff for charge."
                    type="text"
                    class="input"
                    name="tariff"
                    id="tariff"
                    v-model="adhocCharge.tariff"
                  />
                  <label for="totalCharge">Pick Cost:</label>
                  <input
                    placeholder="£"
                    type="number"
                    class="input column is-3"
                    step="0.01"
                    name="totalCharge"
                    id="totalCharge"
                    v-model.number="adhocCharge.totalCharge"
                  />
                  <div class="errorMessage" v-if="errors.totalCharge">
                    <p>{{ errors.totalCharge }}</p>
                  </div>
                  <label for="charge_code">Charge Code:</label>
                  <input
                    list="chargeCodes"
                    class="input column"
                    name="charge_code"
                    id="charge_code"
                    v-model="selectedChargeCode"
                    v-on:change="setChargeCode()"
                  />
                  <datalist id="chargeCodes">
                    <option
                      v-for="chargeCode in chargeCodes"
                      v-bind:value="chargeCode.name"
                      :key="chargeCode.id"
                    ></option>
                  </datalist>
                  <div class="errorMessage" v-if="errors.charge_code">
                    <p>{{ errors.charge_code }}</p>
                  </div>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div>
          <button
            @click="exportToExcel('xlsx')"
            type="button"
            class="mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
          >
            Excel
          </button>
          <button
            @click="exportToExcel('csv')"
            type="button"
            class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
          >
            CSV
          </button>
        </div>
        <v-card>
          <v-card-title>
            Company Billing Data
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="snapHeaders"
            :items="processedData"
            :items-per-page="5"
            :search="search"
            id="data-table"
          >
            <template v-slot:item.chargeDate="{ item }">
              {{ item.chargeDate.split(" ")[0] }}
            </template>
            <template v-slot:item.totalCharge="{ item }">
              <v-chip dark>{{ renderCurrency(item.totalCharge) }}</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-snackbar v-model="snackbar" :timeout="6000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
      <v-dialog v-model="errorDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 red lighten-2">
            Error Submitting Billing Data
          </v-card-title>
          <v-card-text class="mt-2 black--text">
            {{ errorDialogText }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="errorDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import CompaniesUtil from "@/services/CompaniesUtil.js";
import SortedUpload from "@/services/SortedUpload.js";
import BillingSnap from "@/services/BillingSnap.js";

import AdhocCharges from "@/services/AdhocCharges.js";
import CommitData from "@/services/CommitData.js";
import Carriers from "@/services/Carriers.js";

import * as XLSX from 'xlsx'

import DatePicker from "../components/forms/DatePicker";
import BillingCommonFunctions from "@/mixins/BillingCommonFunctions";
import EmailAccManButton from "../components/partials/EmailAccManButton";
export default {
  mixins: [BillingCommonFunctions],
  components: {
    DatePicker,
    EmailAccManButton,
  },
  data() {
    return {
      title: "Submit Invoice Data",
      companySelected: {},
      datesPicked: [],
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      // Dialog
      dialog: false,
      disableAddCharge: false,
      adhocCharge: {
        company_id: null,
        client: null,
        chargeDate: null,
        chargeType: null,
        reference: null,
        clientReference: null,
        UOM: null,
        billableUnits: null,
        tariff: null,
        totalCharge: 0,
        chargeCode: null,
      },
      errors: {
        shipment_id: null,
        pick_cost: null,
        charge_code: null,
        date_applied: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => typeof value == Number || "Value must be a number",
      },
      // Upload
      search: "",
      loading: false,
      data: [],
      // Company
      selectedCompany: [],
      companyName: null,
      valid: false,
      // Query
      query: {
        companyCode: null,
        companyId: null,
        dateShippedFrom: null,
        dateShippedTo: null,
        setInvoiceDate: null,
      },
      queryRun: false,
      // Billing Data
      snapBillingData: [],
      sortedBillingData: [],
      adhocCharges: [],
      processedData: [],
      recurringCharges: [],
      // Company Margins
      companyMargins: [],
      // Snap Table
      snapHeaders: [
        { text: "Client", value: "client" },
        { text: "Charge Date", value: "chargeDate" },
        { text: "Charge Type", value: "chargeType" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "clientReference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billableUnits" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "totalCharge" },
        { text: "Charge Code", value: "chargeCode" },
      ],
      // Charge Codes
      chargeCodes: [],
      selectedChargeCode: null,
      chargeCode: null,
  
      // Combined Charge Codes
      combinedChargeCodes: [],
      // Check Invoice Date Set
      invoiceDateSet: true,
      // Commit Response
      commitDetailedResponse: null,
      // Margins Check
      marginsSet: false,
      marginErrors: null,
      totalAmountCombined: 0,
      totalAverageCharge: 0,
      missingMargin: [],
      averageCharges: [],

      companies: [],
      submittedCompanies: [],
      errorDialog: false,
      errorDialogText: "",

      includeChargeToCommitted: false,
      onlyAddChargeToCommitted: false,
    };
  },
  methods: {
    checkboxToggle(checkbox) {
      switch (checkbox) {
        case 1:
          if (this.includeChargeToCommitted) {
            this.onlyAddChargeToCommitted = false;
          }
          break;
        case 2:
          if (this.onlyAddChargeToCommitted) {
            this.includeChargeToCommitted = false;
          }
          break;
      }
    },

    companyIsSubmitted(companyId) {
      if (this.submittedCompanies && this.submittedCompanies.length) {
        let company = this.submittedCompanies.find(
          (el) => el.company_id == companyId
        );
        return company ? true : false;
      } else {
        return false;
      }
    },
    setCompany(company) {
      this.companySelected = company;
      this.getCompanyData(this.companySelected.id);
    },
    setDateRange(dates) {
      this.datesPicked = dates;
    },
    setAdhocChargeDateRate(dates) {
      if (dates.length === 2) {
        document.getElementById("chargeType").setAttribute("min", dates[0]);
        document.getElementById("chargeType").setAttribute("max", dates[1]);
      }
    },
    setCompanyQuery() {
      this.query.companyCode = this.companySelected.code;
      this.query.companyId = this.companySelected.id;
      this.query.name = this.companySelected.name;
    },
    setDateRangeQuery() {
      this.query.dateShippedFrom = this.datesPicked[0];
      this.query.dateShippedTo = this.datesPicked[1];
    },
    exportToExcel(type) {
      var wb2 = XLSX.utils.book_new();
      var ws2 = XLSX.utils.json_to_sheet(this.processedData);
      XLSX.utils.book_append_sheet(wb2, ws2, "Combined Detailed Data");
      XLSX.writeFile(
        wb2,
        "combinedDetailedData" +
          this.query.companyCode +
          "_" +
          this.query.dateShippedFrom +
          "_" +
          this.query.dateShippedTo +
          "." +
          type
      );
    },
    checkAverageStatus(value) {
      if (value < -20) {
        return "font-weight-bold red--text text--lighten-1";
      } else if (value > 20) {
        return "font-weight-bold green--text text--lighten-1";
      } else {
        return "font-weight-bold";
      }
    },
    async insertAdhocToCommitted(reqBody) {
      AdhocCharges.insertAdhocToCommitted(reqBody)
        .then(() => {
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          this.text = `Adhoc charge added to submitted billing data`;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response.data.message;
          this.loading = false;
          return;
        });
    },
    // Dialog Methods
    save() {
      this.loading = true;
      let invoiceDate = this.query.setInvoiceDate;
      let invoiceNumber = this.generateInvoiceNumber(invoiceDate);

      this.setChargeCode();
      this.adhocCharge.client = this.query.companyCode;
      this.adhocCharge.company_id = this.query.companyId;
      this.adhocCharge.shipment_id =
        this.query.companyCode + this.adhocCharge.shipment_id;

      let reqBody = this.adhocCharge;
      reqBody.onlyAddChargeToCommitted = this.onlyAddChargeToCommitted;
      reqBody.includeChargeToCommitted = this.includeChargeToCommitted;
      reqBody.invoiceNumber = invoiceNumber;
      AdhocCharges.createCompanyCharge(reqBody)
        .then(
          ((response) => {
            this.$set(this, "response", response);
            this.createCompanyInvoice();
            this.loading = false;
            this.snackbar = true;
            this.text = `Charge ${this.adhocCharge.reference} created for ${this.query.name}`;
            this.adhocCharge = Object.assign({}, this.defaultItem);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          if (error.response.status === 400) {
            let errorMessageString = "Error: ";
            error.response.data.errorMessage.forEach((element) => {
              errorMessageString += ` ${element}`;
            });
            this.text = errorMessageString;
          } else {
            this.text = `${error.message}`;
          }
          this.loading = false;
          return;
        });
      this.close();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.addedCharge = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    setChargeCode() {
      let tempChargeCode = this.chargeCodes.find(
        (code) => code.name === this.selectedChargeCode
      );
      this.adhocCharge.chargeCode = tempChargeCode.code;
    },
    // Export Settings
    async insertBillingData() {
      this.loading = true;
      let companyCode = this.query.companyCode;
  
      let todayDate = this.query.setInvoiceDate.split("-");
      // Create Invoice Name
      let invoiceName = `${todayDate[0].slice(0, 4)}${todayDate[1]}${
        todayDate[2]
      }`;
      // Commit Xero Data
      if (companyCode === "FLL") {
        this.query.name = "FairyLoot LTD";
      }
      if (companyCode === "PLO") {
        this.query.name = "Precious Little One Ltd";
      }
      
     if(companyCode === 'ANN'){
        BillingSnap.zeroPickChargesAlert(companyCode,invoiceName )
      }

      // Commit Sorted / Snap Data
      let chargeDate =
        this.query.dateShippedFrom == this.query.dateShippedTo
          ? this.query.dateShippedFrom
          : false;
      CommitData.commitDetailedData(
        this.processedData,
        this.query.companyId,
        invoiceName,
        false,
        chargeDate
      )
        .then(
          ((commitDetailedResponse) => {
            this.$set(this, "commitDetailedResponse", commitDetailedResponse);
            this.text = `Successfully committed Detailed Billing data for ${this.query.name}.`;
            this.snackbar = true;
            this.loading = false;

            this.getSubmittedCompaniesByInvoiceNumber(
              this.query.setInvoiceDate
            );
          }).bind(this)
        )
        .catch((error) => {
          this.errorDialogText = `${error.response.data.errorMessage[0]}`;
          this.errorDialog = true;
          this.loading = false;
          return;
        });
    },
    getTrackingOption(companyCode, trackingOption){
      switch(companyCode){
        case "FMW":
           return "FM World"
        case "FLL":
          return "Fairyloot"
        default:
          return trackingOption
      }
 
    },
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    setCompanyCode() {
      this.getCompanyData(this.companySelected.id);
      this.query.companyCode = this.companySelected.code;
      this.query.companyId = this.companySelected.id;
      this.adhocCharge.client = this.companySelected.code;
    },
    enableFileExport() {
      if (this.query.setInvoiceDate) {
        this.invoiceDateSet = false;
      }
    },

    // Charge Codes
    async getChargeCodes(companyId) {
      // Use the BillingSnap to call the getBillingData() method
      return AdhocCharges.getChargeCodes(companyId)
        .then(
          ((chargeCodes) => {
            this.$set(this, "chargeCodes", chargeCodes);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async getPalletisedInboundsBilling(companyId, dateFrom, dateTo) {
      // Use the BillingSnap to call the getBillingData() method
      let params = {
        companyIds: [companyId],
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
      return BillingSnap.getPalletisedInboundsBilling(params);
    },
    async getCompanyData(companyID) {
      // Use the BillingSnap to call the getBillingData() method
      CompaniesUtil.getCompany(companyID)
        .then(
          ((selectedCompany) => {
            this.$set(this, "selectedCompany", selectedCompany);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
  
    async createCompanyInvoice() {
      try {
        this.processedData = [];
        this.loading = true;

        this.setCompanyQuery();
        this.setDateRangeQuery();

        const companyId = this.query.companyId;
        const dateFrom = this.query.dateShippedFrom;
        const dateTo = this.query.dateShippedTo;

        const chargeCodes = await this.getChargeCodes(companyId);
        if (!chargeCodes)
          throw new Error("Failed to get charge codes for selected company");

        const shippingCharges = await this.getDistChargesWithMargins(companyId, dateFrom, dateTo);

        // Fetch Snap billing data
        const snapBillingData = await BillingSnap.getBillingDataDaterange(
          this.query,
          dateFrom,
          dateTo
        );

        const adhocCharges = await this.getAdhocCharges(companyId, dateFrom, dateTo);
        const recurringCharges = await this.getRecurringCharges();

        const palletisedInboundBilling =
          await this.getPalletisedInboundsBilling(companyId, dateFrom, dateTo);

        const fusionCharge = await this.getFusionCharge(companyId, dateFrom, dateTo);

        // store charges on their own variables to be used on the summary
        this.adhocCharges = adhocCharges;
        this.sortedBillingData = shippingCharges;
        this.snapBillingData = snapBillingData;
        this.recurringCharges = recurringCharges;

        //combine all charges into variable processedData as the final data to bill
        this.combineDataSets(shippingCharges);
        this.combineDataSets(snapBillingData);
        this.combineDataSets(adhocCharges);
        this.combineDataSets(recurringCharges);
        this.combineDataSets(palletisedInboundBilling);
        this.combineDataSets(fusionCharge);

        this.showSnackbar(
          `Successfully Loaded Data between ${this.query.dateShippedFrom} and ${this.query.dateShippedTo}`
        );

        this.getCompanyAverages();
      } catch (error) {
        this.handleError(error);
      }
    },

    async getFusionCharge(companyId, dateFrom, dateTo) {
      const tempCharges = [];
      const fusionLicenceCharge = await BillingSnap.getFusionLicenceCharge(
        companyId, dateFrom, dateTo
      );
      // check if charge exists, data will return null if there are no licences to charge for (if they are all free for example)
      if (fusionLicenceCharge) {
        let tempObj = {
          client: fusionLicenceCharge.client,
          chargeDate: fusionLicenceCharge.charge_date,
          chargeType: fusionLicenceCharge.charge_type,
          reference: fusionLicenceCharge.reference,
          clientReference: fusionLicenceCharge.client_reference,
          UOM: fusionLicenceCharge.UOM,
          billableUnits: fusionLicenceCharge.billable_units,
          tariff: fusionLicenceCharge.tariff,
          totalCharge: fusionLicenceCharge.total_charge,
          chargeCode: fusionLicenceCharge.charge_code,
        };
        tempCharges.push(tempObj);
      }

      return tempCharges;
    },

    async getDistChargesWithMargins(companyId, dateFrom, dateTo) {
      try{
        this.missingMargin = []
        this.marginErrors = false
        let res = await Carriers.getDistChargesWithMargins(companyId, dateFrom, dateTo)
        return res.data
      }catch(err){
        this.missingMargin.push(err.response.data.message)
        this.marginErrors = true
        throw err
      }
     
    },

    async getAdhocCharges(companyId, dateFrom, dateTo) {
      const tempAdHoc = [];
      // Fetch Adhoc Charges
      const adhocCharges = await AdhocCharges.getAdhocCharges(
        companyId, dateFrom, dateTo
      );

      adhocCharges.forEach((element) => {
        let tempObj = {
          client: element.client,
          chargeDate: element.charge_date,
          chargeType: element.charge_type,
          reference: element.reference,
          clientReference: element.client_reference,
          UOM: element.UOM,
          billableUnits: element.billable_units,
          tariff: element.tariff,
          totalCharge: parseFloat(element.total_charge),
          chargeCode: element.charge_code,
        };
        tempAdHoc.push(tempObj);
        this.queryRun = true;
      });
      return tempAdHoc;
    },
    async getCompanyAverages() {
      // Fetch Company Averages
      const averageCharges = await SortedUpload.getCompanyAverages(
        this.query.companyId
      );
      this.averageCharges = [];
      this.totalAverageCharge = 0;
      this.averageCharges = averageCharges;
      averageCharges.forEach((charge) => {
        this.totalAverageCharge += parseInt(charge.average);
      });
    },
    async getRecurringCharges() {
      const tempRecurring = [];
      // Fetch Recurring Charges
      const recurringCharges = await AdhocCharges.getRecurringCharges(
        this.query.companyId
      );

      if (recurringCharges.length > 0) {
        recurringCharges.forEach((element) => {
          let tempObj = {
            client: element.client,
            chargeDate: this.query.dateShippedFrom,
            chargeType: element.charge_type,
            reference: element.reference,
            clientReference: element.client_reference,
            UOM: element.UOM,
            billableUnits: element.billable_units,
            tariff: element.tariff,
            totalCharge: parseFloat(element.total_charge),
            chargeCode: element.charge_code,
          };
          // recalculate recurring charge for daily billing
          if (
            element.recurring === "weekly" &&
            this.query.dateShippedFrom === this.query.dateShippedTo
          ) {
            tempObj.clientReference = "Daily";
            let dailyTariff =
              Math.round(
                (parseFloat(element.tariff) / 7 + Number.EPSILON) * 100
              ) / 100;
            let dailyTotalCharge =
              Math.round(
                (parseFloat(element.total_charge) / 7 + Number.EPSILON) * 100
              ) / 100;
            tempObj.tariff = dailyTariff;
            tempObj.totalCharge = dailyTotalCharge;
          }
          tempRecurring.push(tempObj);
          this.queryRun = true;
        });
      }
      return tempRecurring;
    },

    handleError(error) {
      this.showSnackbar(`${error.message}`);
    },

    showSnackbar(message) {
      this.text = message;
      this.snackbar = true;
      this.loading = false;
    },

    changeDpdRate(totalCost) {
      if (!totalCost) return null;
      switch (totalCost.toFixed(2)) {
        case "5.73":
          return 4.57;
        default:
          return totalCost;
      }
    },
    combineDataSets(dataSet) {
      this.processedData = this.processedData.concat(dataSet);
    },
    percentageDiff(averageCharge) {
      let currentCharge = this.combinedChargeCodes.find(
        (charge) => charge.code === averageCharge.account_code
      );
      if (currentCharge) {
        const A = parseInt(currentCharge.amount);
        const B = parseInt(averageCharge.average);
        const percent = this.getPercentageChange(B, A);

        return percent;
      } else {
        return 0;
      }
    },
    getSubmittedCompaniesByInvoiceNumber(invoiceDate) {
      let invoiceNumber = this.generateInvoiceNumber(invoiceDate);
      this.loading = true;
      BillingSnap.getSubmittedCompaniesByInvoiceNumber(invoiceNumber)
        .then((submittedCompanies) => {
          this.submittedCompanies = submittedCompanies;
        })
        .finally(() => (this.loading = false));
    },
    getPercentageChange(oldNumber, newNumber) {
      var decreaseValue = oldNumber - newNumber;
      return ((decreaseValue / oldNumber) * -100).toFixed(0);
    },
    async loadInitialData() {
      let companyArray = await this.$root.sharedData.getCompanies();
      if (companyArray) {
        this.companies = companyArray;
      }

      this.loading = true;
      let chargeCodes = await this.getChargeCodes("");
      if (chargeCodes) {
        this.showSnackbar(
          `Select Company and Shipping Dates to view combined data with margins applied.`
        );
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  computed: {
    alreadySubmitted() {
      if (
        !this.query ||
        !this.query.companyId ||
        this.submittedCompanies.length < 1
      )
        return false;
      let companyId = this.query.companyId;
      let companySubmitted = this.submittedCompanies.find(
        (el) => el.company_id == companyId
      );
      return companySubmitted;
    },
    computeChargeCode() {
      if (this.processedData.length > 0) {
        return this.processedData;
      } else {
        return null;
      }
    },
    invoiceNumber() {
      if (!this.query.setInvoiceDate) return "";
      return this.generateInvoiceNumber(this.query.setInvoiceDate);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    datesPicked: {
      handler: function (newVal) {
        if (newVal && newVal[1]) {
          let invoiceDate = this.getInvoiceDate(newVal[1]);
          this.query.setInvoiceDate = invoiceDate.toISOString().substr(0, 10);
          this.getSubmittedCompaniesByInvoiceNumber(this.query.setInvoiceDate);
          this.enableFileExport();
        }
      },
    },

    computeChargeCode: {
      handler: function (newVal) {
        if (!newVal) return;
        let totalAmount = 0;
        this.combinedChargeCodes = [];
        this.chargeCodes.forEach((element) => {
          let tempCode = element.code;
          let codeName = this.chargeCodes.find(
            (data) => data.code === tempCode
          );
          let temp = newVal.filter((data) => data.chargeCode == tempCode);
          if (temp.length > 0) {
            let tempAmount = temp.map((charge) =>
              parseFloat(charge.totalCharge)
            );
            let reduceAmount = tempAmount.reduce(function (acc, amount) {
              return acc + amount;
            });
            totalAmount += reduceAmount;
            this.combinedChargeCodes.push({
              code: tempCode,
              name: codeName.name,
              TrackingOption1: codeName.TrackingOption1,
              amount: reduceAmount.toFixed(2),
            });
          }
        });
        this.totalAmountCombined = totalAmount;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}
.summary {
  display: flex;
  .box-div {
    padding: 1em;
  }
}
</style>
