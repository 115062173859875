<template>
  <div>
    <v-btn
      @click="sendEmail()"
      type="button"
      color="primary"
      class="mb-2"
      :disabled="!invoiceNumber"
      :loading="loading"
      >Email Submitted Data To Account Managers</v-btn
    >
    <Alert :snackbar="snackbar" :text="text" :timeDelay="3000"></Alert>
  </div>
</template>

<script>
import CommitedData from "@/services/CommitedData.js";
import Alert from "@/components/partials/Alert";

export default {
  name: "EmailAccManButton",
  props: ["invoiceNumber"],
  components: { Alert },
  data() {
    return {
      snackbar: false,
      text: "",
      loading: false,
    };
  },
  methods: {
    sendEmail() {
      let confirmMsg = `Please make sure all relevant invoices have been submitted and account manager emails have been setup for the respective companies.
        Press Ok to email account managers for invoice number ${this.invoiceNumber}`;

      if (!confirm(confirmMsg)) return;
      if (!this.invoiceNumber) {
        this.text = "Invoice number required";
        this.snackbar = true;
      }
      this.loading = true;
      CommitedData.sendUncommittedDataToAccountManager(this.invoiceNumber)
        .then((res) => {
          this.loading = false;
          this.snackbar = true;
          this.text = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbar = true;
          this.text = err.message;
        });
    },
  },
};
</script>
