import axios from 'axios';

const carrierUrl = `${process.env.VUE_APP_FUSION_API_URL}/carriers/`;
const betaCarrierUrl = `${process.env.VUE_APP_BETA_API_URL}/carriers`
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
// const carrierUrl = "http://3plapi.test/carriers/"
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"

// const carriersApiUrl = 'http://localhost:3250/api'
const carriersApiUrl = process.env.VUE_APP_CARRIERS_API_URL
const carriersApiAuthToken = process.env.VUE_APP_CARRIERS_API_AUTH_TOKEN

const warehouseAPI = process.env.VUE_APP_WAREHOUSE_API_URL;
const AUTH_TOKEN = process.env.VUE_APP_WAREHOUSE_API_AUTH_TOKEN;

const carriersApiHeaders = { Authorization: carriersApiAuthToken}

export default {
  async getCarriers() {
    let res = await axios.get(`${carrierUrl}?API-KEY=${API_KEY}`);
    return res.data.data;
  },
  async updateCarrierService(formData) {
    var myHeaders = new Headers();
    let formArray = formData;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formArray,
      redirect: "follow",
    };

    let result = await fetch(carrierUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => (this.result = result));
    return result;
  },
  async createCarrierService(formData) {
    var myHeaders = new Headers();
    let formArray = formData;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formArray,
      redirect: "follow",
    };

    let result = await fetch(carrierUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => (this.result = result));
    return result;
  },
  async deleteCarrierService(formData) {
    var myHeaders = new Headers();
    let formArray = formData;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: formArray,
      redirect: "follow",
    };

    let result = await fetch(carrierUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => (this.result = result));
    return result;
  },
  async getReconcilitionData(carrier, dateFrom, dateTo) {
    if (!carrier) throw Error("Please select a Carrier.");
    if (!dateFrom) throw Error("Please select Date From");
    if (!dateTo) throw Error("Please select a Date To");
    if (carrier === "Royal Mail") carrier = "RoyalMail";
    if (carrier === "Hermes ROTW") carrier = "HermesROTW";
    if (carrier === "DPD Local") carrier = "DPDLocal";
    let res = await axios.get(
      `${carrierUrl}reconcile_carrier_data?API-KEY=${API_KEY}&carrier=${carrier}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
    return res.data.data;
  },
  async storeDistributionRevenueData(revenueData) {
    let body = {
      "API-KEY": API_KEY,
      json: JSON.stringify(revenueData),
    };
    let res = await axios.post(`${carrierUrl}carrier_reconciliation`, body);
    return res.data.data;
  },
  async getReconciledData(weekCommencing) {
    let res = await axios.get(
      `${carrierUrl}carrier_reconciled?API-KEY=${API_KEY}&week_commencing=${weekCommencing}`
    );
    return res.data.data;
  },
  async getWeeksCommencing() {
    let res = await axios.get(
      `${carrierUrl}reconciled_week_commencing?API-KEY=${API_KEY}`
    );
    return res.data.data;
  },
  async uploadCharges(formData, type) {
    let url = `${carrierUrl}${type}`;

    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "API-KEY": API_KEY,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData, // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },
  async getInvoiceReport(carrier, week) {
    const response = await fetch(
      `${carrierUrl}report_invoice?API-KEY=${API_KEY}&carrier=${carrier}&week=${week}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
    return response.json(); // parses JSON response into native JavaScript objects
  },
  async getInvoiceWeeks(carrier) {
    const response = await fetch(
      `${carrierUrl}invoice_weeks?API-KEY=${API_KEY}&carrier=${carrier}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
    return response.json(); // parses JSON response into native JavaScript objects
  },

  async getServiceGroups(onlyDirectIntegration){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let options = null
    if(onlyDirectIntegration){
      options = {params: {onlyDirectIntegration: true, timestamp: new Date().getTime() }}
    }
    let url = `${carriersApiUrl}/services/carrier-service-group`;
    let res = await axios.get(url, options);
    return res.data;
  },
  async saveServiceGroup(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service-group`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async getCarrierServices(){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service`;
    let res = await axios.get(url);
    return res.data;
  },
  async getCarriersWithServices(){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier`;
    let res = await axios.get(url);
    return res.data;
  },
  async getCarrierBillingZones(){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-zone`;
    let res = await axios.get(url, {params: { timestamp: new Date().getTime() }});
    return res.data;
  },
  async insertCarrierBillingZonesMapping(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-zones-mapping`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async getCarrierBillingZoneMapping(carrierBillingZoneId){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-zones-mapping`;
    let res = await axios.get(url, {params: {carrierBillingZoneId: carrierBillingZoneId, timestamp: new Date().getTime() }});
    return res.data;
  },
  async insertCarrierBillingZone(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-zone`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async getCarrierBillingRates(carrierId,carrierServiceId, companyId){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-rate`;
    let options = {params: {carrierId: carrierId, carrierServiceId: carrierServiceId , timestamp: new Date().getTime()}}
    if(companyId){
      options.params.companyId = companyId
    }
    let res = await axios.get(url, options);
    return res.data;
  },
  async insertCarrierBillingRates(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-rate`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async updateCarrierBillingRate(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-rate`;
    let res = await axios.put(url, reqBody);
    return res.data;
  },
  async deleteCarrierBillingRate(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-rate`;
    let res = await axios.delete(url, {data: reqBody});
    return res.data;
  },
  async getCarrierBillingSurcharges(carrierServiceId){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-surcharge`;
    let res = await axios.get(url, {params: {carrierServiceId: carrierServiceId, timestamp: new Date().getTime() }});
    return res.data;
  },
  async insertCarrierBillingSurcharge(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-surcharge`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async updateCarrierBillingSurcharge(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-surcharge`;
    let res = await axios.put(url, reqBody);
    return res.data;
  },
  async deleteCarrierBillingSurcharge(reqBody) {
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-billing-surcharge`;
    let res = await axios.delete(url, {data:reqBody});
    return res.data;
  },
  async getCarrierServiceSettings(carrierServiceId){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service-settings`;
    let res = await axios.get(url, {params: {carrierServiceId: carrierServiceId, timestamp: new Date().getTime() }});
    return res.data;
  },
  async saveCarrierServiceSettings(reqBody){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service-settings`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async getCarrierServiceTags(carrierServiceId){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service-tags`;
    let res = await axios.get(url, {params: {carrierServiceId: carrierServiceId, timestamp: new Date().getTime() }});
    return res.data;
  },
  async insertCarrierServiceTag(reqBody) {
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service-tags`;
    let res = await axios.post(url,reqBody);
    return res.data;
  },
  async deleteCarrierServiceTag(reqBody) {
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/services/carrier-service-tags`;
    let res = await axios.delete(url, {data:reqBody});
    return res.data;
  },
  async getAvailableCarrierServices(serviceGroup, country, postCode, numberOfPackages, maxWeight, companyId ){
    axios.defaults.headers.common["Authorization"] = carriersApiAuthToken;
    let url = `${carriersApiUrl}/carriers/available-carrier-services`;
    let params = {
      serviceCode: serviceGroup,
      country: country, 
      postCode: postCode,
      numberOfPackages: numberOfPackages,
      maxWeight: maxWeight,
      companyId: companyId,
      timestamp: new Date().getTime() 
    }
    
    let res = await axios.get(url, {params: params});
    return res.data;
  },
  async getCountries() {
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/utilities/countries/`;
    let res = await axios.get(url);            
    return res.data.data;
  },
  async uploadShipmentInvoice(data, carrier) {
    const response = await fetch(`${betaCarrierUrl}/shipments/${carrier}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "API-KEY": API_KEY,
        'Accept': 'application/json',
      },
      redirect: "follow",
      referrerPolicy: "strict-origin-when-cross-origin",
      body: data,
    });
    return response.json();
  },


  async saveInvoiceCharges(reqBody){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/invoice-charges`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async validateOrderNumbers(reqBody){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/validate-order-numbers`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async findOrderNumbersFromReferences(reqBody){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/find-order-numbers-from-references`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async validateInvoiceExists(reqBody){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/validate-invoice-exists`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
  async getReconciliationReport(invoiceNumber, carrierId, companyId, dateFrom, dateTo){
    let params = {
      invoiceNumber: invoiceNumber,
      carrierId: carrierId,
      companyId: companyId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      timestamp: new Date().getTime() 
    }
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/reconciliation-report`;
    let res = await axios.get(url, {params: params});
    return res.data;
  },
  async getStandardisedCharges(orderNumber, invoiceNumber){
    let params = {
      orderNumber: orderNumber,
      invoiceNumber: invoiceNumber,
      timestamp: new Date().getTime() 
    }
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/standardised-charges`;
    let res = await axios.get(url, {params: params});
    return res.data;
  },
  async getBilledCharges(orderNumber){
    let params = {
      orderNumber: orderNumber,
      timestamp: new Date().getTime() 
    }
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/billed-charges`;
    let res = await axios.get(url, {params: params});
    return res.data;
  },
  async getInvoicesList(carrierId){
    let params = {
      timestamp: new Date().getTime(),
      carrierId: carrierId 
    }
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}/billing/invoice-rec/standardised-invoices-list`;
    let res = await axios.get(url, {params: params});
    return res.data;
  },

  async getMargins(companyId, carrierId,carrierServiceId = null){
    const params = {
      companyId: companyId,
      carrierId: carrierId,
      timestamp: new Date().getTime() 
    }
    if(carrierServiceId){
      params.carrierServiceId = carrierServiceId
    }
    let url = `${carriersApiUrl}/margins`;
    let res = await axios.get(url, {params: params, headers: carriersApiHeaders});
    return res.data;
  },
  async insertMargin(reqBody){
    let url = `${carriersApiUrl}/margins`;
    let res = await axios.post(url, reqBody, { headers: carriersApiHeaders });
    return res.data;
  },
  async bulkInsertMargins(reqBody){
    let url = `${carriersApiUrl}/margins/bulk-save-margins`;
    let res = await axios.post(url, reqBody, { headers: carriersApiHeaders });
    return res.data;
  },
  async updateMargin(reqBody){
    let url = `${carriersApiUrl}/margins`;
    let res = await axios.put(url, reqBody, { headers: carriersApiHeaders });
    return res.data;
  },
  async deleteMargin(reqBody) {
    let url = `${carriersApiUrl}/margins`;
    let res = await axios.delete(url, {data: reqBody, headers: carriersApiHeaders});
    return res.data;
  },
  async getDistChargesWithMargins(companyId, dateFrom, dateTo){
    const params = {
      companyId: companyId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      timestamp: new Date().getTime() 
    }
    let url = `${carriersApiUrl}/margins/dist-charges-with-margins`;
    let res = await axios.get(url, {params: params, headers: carriersApiHeaders});
    return res.data;
  },


};
