<template>
    <v-snackbar
    v-model="snackbar"
    :timeout="timeDelay"
    >
    {{ text }}
    <v-btn
      color="blue"
      text
      @click="display = false"
    >
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    snackbar: Boolean,
    timeDelay: Number,
    text: String
  },
}
</script>

<style>

</style>